@import '../theme-bootstrap';

.site-email-sms-signup {
  max-width: $max-width;
  margin: 30px auto 20px;
  padding: 50px 90px 100px;
  background: url(/media/images/account/email-sms-signup-envelope.gif) no-repeat center top;
  @include breakpoint($medium-down) {
    width: 100%;
  }
  @include breakpoint($small-down) {
    background: none;
    padding: 30px 1%;
    margin-top: 10px;
  }
  &__header {
    width: 600px;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: center;
    @include breakpoint($medium-down) {
      margin-bottom: 20px;
    }
    @include breakpoint($small-down) {
      width: 100%;
      padding: 44px 0 20px;
      background: url(/media/images/account/email-sms-signup-envelope-small.gif) no-repeat center top;
      background-size: 95%;
      border-bottom: 2px solid $color-gray;
    }
    h1 {
      height: 40px;
      margin-bottom: 18px;
      font-family: $main-font;
      font-weight: 500;
      font-size: 3em;
      letter-spacing: 6px;
      line-height: 1;
      text-transform: uppercase;
      @include breakpoint($small-down) {
        height: auto;
        margin-bottom: 60px;
        font-size: 2em;
        letter-spacing: 4px;
      }
    }
    h2 {
      margin: 0;
      font-size: 1.5em;
      line-height: 1.25;
      @include breakpoint($small-down) {
        font-size: 1em;
      }
    }
  }
  &__field {
    display: inline-block;
    width: 255px;
    margin: 10px;
    @include breakpoint($medium-down) {
      width: 45.5%;
      margin: 8px 2%;
    }
    @include breakpoint($small-down) {
      width: 96%;
    }
  }
  &__required-note {
    @include swap_direction(margin, 0 0 0 10px);
    @include breakpoint($medium-down) {
      width: 96%;
      margin: 0 2%;
    }
  }
  &__birthday {
    text-align: $rdirection;
  }
  &__birthday-label {
    display: inline-block;
    width: 60px;
    @include swap_direction(margin, 0 10px 0 0);
    @include breakpoint($medium-down) {
      width: 25%;
      float: $ldirection;
      text-align: $ldirection;
      line-height: get-line-height(14px, 30px);
    }
  }
  &__month,
  &__day {
    width: 48px;
    margin: 0 4px;
    padding: 10px 8px;
    @include breakpoint($medium-down) {
      width: 17.5%;
      margin: 0 1%;
    }
  }
  &__year {
    width: 55px;
    margin-#{$ldirection}: 4px;
    padding: 10px 8px;
    @include breakpoint($medium-down) {
      width: 25%;
      margin-#{$ldirection}: 1%;
    }
  }
  &__agree {
    display: inline-block;
    width: 532px;
    margin: 10px;
    @include breakpoint($medium-down) {
      width: 71%;
      margin: 8px 2%;
    }
    @include breakpoint($small-down) {
      width: 96%;
    }
    input {
      position: static;
      float: $ldirection;
      margin-top: 5px;
      width: 20px;
    }
    p.site-email-sms-signup__agree--terms {
      display: inline;
      padding-#{$ldirection}: 5px;
      margin: 0;
      width: 480px;
    }
  }
  &__submit {
    margin: 10px;
    vertical-align: top !important;
    @include breakpoint($medium-down) {
      height: 60px;
      line-height: get-line-height(14px, 60px);
      margin: 8px 2%;
    }
    @include breakpoint($small-down) {
      width: 96% !important;
      height: 40px;
      line-height: get-line-height(14px, 40px);
    }
  }
  &__error {
    display: inline-block !important;
    width: 100%;
    margin: 60px 10px 10px;
    color: $color-pink-red;
    font-family: $main-font;
    font-weight: 500;
    text-align: $ldirection;
    @include breakpoint($medium-down) {
      width: 96%;
      margin: 30px 2% 8px;
    }
    @include breakpoint($small-down) {
      margin-top: 10px;
    }
  }
  .missing-error {
    color: $color-pink-red;
    border-color: $color-pink-red;
  }
  .missing-error::-webkit-input-placeholder {
    color: $color-pink-red;
  }
  .missing-error::-moz-placeholder {
    color: $color-pink-red;
  }
  .missing-error:-moz-input-placeholder {
    color: $color-pink-red;
  }
  .missing-error:-ms-input-placeholder {
    color: $color-pink-red;
  }
}
